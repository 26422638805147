<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Sửa Cấu hình khuyến mãi</h6>
              </template>

              <b-card-body>
                <b-form-row class="mb-2">
                  <b-col>
                    <b-form-group>
                      <label for="">Nhà mạng</label>
                      <b-form-select
                        class="form-control"
                        label="Nhà mạng"
                        :options="providers"
                        v-model="params.provider"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label for="">Loại dịch vụ</label>
                      <b-form-select
                        class="form-control"
                        label="Loại dịch vụ"
                        :options="actions"
                        v-model="params.type"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label for="">Trạng thái</label>
                      <b-form-select
                        class="form-control"
                        label="Trạng thái"
                        :options="status"
                        v-model="params.is_active"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group label="Từ ngày">
                      <b-form-input
                        :id="`type-date`"
                        :type="`date`"
                        v-model="params.start_date"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Đến ngày">
                      <b-form-input
                        :id="`type-date`"
                        :type="`date`"
                        v-model="params.end_date"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row class="float-right">
                  <b-button class="mr-2" @click="clickClose()">Đóng</b-button>
                  <b-button class="btn btn-success" @click="updatePromotion()"
                    >Cập nhật</b-button
                  >
                </b-form-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import CmsRepository from "@/core/repositories/cmsRepository";
import moment from "moment";

export default {
  name: "Detail",
  mixins: [Common],
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      promotion: null,
      params: {},
      providers: [
        { value: "VIETTEL", text: "VIETTEL" },
        { value: "VINAPHONE", text: "VINAPHONE" },
        { value: "MOBIFONE", text: "MOBIFONE" },
        { value: "GMOBILE", text: "GMOBILE" },
      ],
      actions: [
        { value: "TOPUP_MOBILE", text: "Nạp tiền điện thoại" },
        { value: "TOPUP_DATA", text: "Nạp data 3G/4G" },
      ],
      status: [
        { value: 1, text: "Hoạt động" },
        { value: 0, text: "Tạm dừng" },
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sửa cấu hình khuyến mãi" },
    ]);
    this.getPromotion();
  },
  methods: {
    async getPromotion() {
      this.$bus.$emit("show-loading", false);
      CmsRepository.viewPromotion(this.id).then((response) => {
        if (response.status !== 200) {
          alert(response.message);
          return false;
        }
        let result = response.data;
        if (result.error_code !== 0) {
          alert(result.message);
          return false;
        }
        this.params = result.data;
        this.params.start_date = this.formatDate(this.params.start_date);
        this.params.end_date = this.formatDate(this.params.end_date);
        return true;
      });
    },
    async updatePromotion() {
      this.$bus.$emit("show-loading", false);
      CmsRepository.updatePromotion(this.params.id, this.params)
        .then((response) => {
          if (response.status !== 200) {
            alert(response.message);
            return false;
          }
          let result = response.data;

          if (result.error_code !== 0) {
            alert(result.message);
            return false;
          }

          alert('Cập nhật thành công');

          return this.$router.push({ name: "promotion" });
        })
        .catch(() => {
          alert("Có lỗi xảy ra, vui lòng thử lại");
        });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
      return value;
    },
    clickClose() {
      if (!confirm("Bạn chắc chắn muốn quay lại danh sách?")) {
        return;
      }
      this.$router.push({ name: "promotion" });
    },
  },
};
</script>
